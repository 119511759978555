import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, getDoc, onSnapshot, Timestamp, updateDoc } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { dbFirestore, auth, signOut } from '../../firebase';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { Link } from "react-router-dom";
import Profile from './Profile';



function ManageOrders() {
    const [users, setUsers] = useState([]);
    const [userDomains, setUserDomains] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [isPopupVisible, setPopupVisible] = useState(false);

  const [isProfilePopupVisible, setProfilePopupVisible] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to manage selected user ID for the popup


  const { userId } = useParams();


  const navigate = useNavigate();

  
  
     // Function to handle user logout
     const handleLogout = async () => {
      try {
        await signOut(auth);
        navigate('/sign-in'); // Redirect to the login page after logout
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };
  

    const showProfilePopup = (userId) => {
        setSelectedUserId(userId); // Set the selected userId to display in the profile popup
        setPopupVisible(true); // Show the popup
      };

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersRef = collection(dbFirestore, 'account');
                const usersSnapshot = await getDocs(usersRef);
                const usersData = usersSnapshot.docs.map(doc => {
                    const userData = doc.data();
                    return {
                        id: doc.id,
                        ...userData,
                        // Assuming 'disabled' field exists in the user document
                        isAuthEnabled: !userData.disabled // Check if user account is enabled
                    };
                });
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };

        fetchUsers();
    }, [])

    useEffect(() => {
      const fetchUserDomains = async () => {
        try {
          const userDomainsRef = collection(dbFirestore, 'users', userId, 'domains');
          const userDomainsQuery = query(userDomainsRef);
          const userDomainsSnapshot = await getDocs(userDomainsQuery);
          const userDomainsData = userDomainsSnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setUserDomains(userDomainsData);
          setIsLoading(false);
        } catch (error) {
          console.error('Error fetching user domains:', error);
          setIsLoading(false);
        }
      };
  
      fetchUserDomains();
    }, [userId]);

    const toggleAuthStatus = async (userId, isAuthEnabled) => {
        try {
            const userDocRef = doc(dbFirestore, 'account', userId);
            await updateDoc(userDocRef, {
                disabled: !isAuthEnabled // Invert the current authentication status
            });
            // Update the local state to reflect the change
            setUsers(users.map(user => {
                if (user.id === userId) {
                    return { ...user, isAuthEnabled: !isAuthEnabled };
                }
                return user;
            }));
        } catch (error) {
            console.error('Error toggling authentication status:', error);
        }
    };

    return (
        <div>
            <h2>All Affiliate Orders</h2>
            <table>
                <thead>
                    <tr> 
                        <th>User No.</th>
                        <th>Unique Code</th>
                        
                        <th>Manage Order</th>
                        <th>Profile</th>
                        
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index, domain) => (
                        <tr key={user.id}>
                            <td>{index + 1}</td>
                            <td>{user.uniqueCode}</td>
                            
                            <td>
      
            <Link to={`/user-admin/${user.id}`}>View Orders</Link>
         
       
      </td>
                            <td><Link to={`/profile/${user.id}`}>View Profile</Link></td>
                            
                        </tr>
                    ))}
                </tbody>
            </table>
            
      {isPopupVisible && (
        <div className="user-profile-popup">
          <Profile userId={selectedUserId} onClose={() => setPopupVisible(false)} />
        </div>
      )}
        </div>
    );
}

export default ManageOrders;
