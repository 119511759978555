// Notification.js (if you haven't already created it)
import React, { useState, useEffect } from 'react';
import "./Notification.css";

const Notification = ({ message, duration, onClose }) => {
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setVisible(false);
      onClose(); // Call the onClose function when the notification disappears
    }, duration);

    return () => {
      clearTimeout(timer);
    };
  }, [duration, onClose]);

  return visible ? (
    <div className="notification">
      {message}
    </div>
  ) : null;
};

export default Notification;
