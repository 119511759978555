import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, getDoc, onSnapshot, addDoc, deleteDoc, doc, setDoc, doc as firestoreDoc } from 'firebase/firestore';
import { auth, dbFirestore, signOut } from '../../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import Notification from '../../Notification';
import { useNavigate, Link } from "react-router-dom";

import LoadingSpinner from '../../LoadingSpinner';

import { Icon } from '@iconify/react';

const profileStyle = {
  backgroundColor: '#ffffff',
};

const requirementOptions = ["Option 1", "Option 2", "Option 3"];

const getFormattedDate = () => {
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  };
  const currentDate = new Date().toLocaleDateString(undefined, options);
  return currentDate;
};

function Statistics({ userId }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [notification, setNotification] = useState(null);
  const [domains, setDomains] = useState([]);
  const [editedDomain, setEditedDomain] = useState(null);
  const [showEmptyRow, setShowEmptyRow] = useState(false);
  const [payCycle, setPayCycle] = useState('');
  const [showScreenChanges, setShowScreenChanges] = useState(false);
 
  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(3);

  const [expandedRows, setExpandedRows] = useState({});
 
  const navigate = useNavigate();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

  useEffect(() => {
    if (user) {
      fetchDomains();
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      const unsubscribe = onSnapshot(doc(dbFirestore, 'account', user.uid), (docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setPayCycle(userData.payCycle || '');
        } else {
          console.log('User not found');
        }
      });

      return () => unsubscribe();
    }
  }, [user]);

  const fetchDomains = async () => {
    try {
      const userDomainsRef = collection(dbFirestore, 'users', user.uid, 'domains');
      const q = query(userDomainsRef);
      const querySnapshot = await getDocs(q);
      const domainsData = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          id: doc.id,
          ...data,
          orderValue: data.orderValue || data.value,
        };
      });
      setDomains(domainsData);
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching domains:', error);
      setIsLoading(false);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in');
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  const addDomain = () => {
    setEditedDomain({
      domainName: '',
      domainEmail: '',
      phone: '',
      requirement: '',
      description: '',
      value: '',
      orderPayment: 'No',
      capture: '',
      orderDate: getFormattedDate(),
      ownerId: user.uid,
    });
    setShowEmptyRow(true);
  };


  const removeDomain = async (domainId) => {
    if (user) {
      const userDomainDocRef = doc(dbFirestore, 'users', user.uid, 'domains', domainId);
      try {
        await deleteDoc(userDomainDocRef);
        // Update earnings after removing domain
        
        fetchDomains();
        setNotification('Domain was successfully removed');
      } catch (error) {
        setNotification('Error in removing domain');
        console.error('Error removing domain:', error);
      }
    }
  };
  
  const editDomain = (domainId) => {
    const editedDomain = domains.find(domain => domain.id === domainId);
    setEditedDomain(editedDomain);
    setShowEmptyRow(true);
  };

  const saveChanges = async () => {
    if (user && editedDomain) {
      try {
        if (editedDomain.id) {
          const userDomainDocRef = doc(dbFirestore, 'users', user.uid, 'domains', editedDomain.id);
          await setDoc(userDomainDocRef, editedDomain, { merge: true });
        } else {
          const userDomainsRef = collection(dbFirestore, 'users', user.uid, 'domains');
          // Update orderValue before adding to Firestore
          const orderValue = parseFloat(editedDomain.value); // Parse the value to float
          const domainToAdd = { ...editedDomain, orderValue: orderValue };
          await addDoc(userDomainsRef, domainToAdd);
        }
        fetchDomains();
        
        setNotification('Changes were successfully done.');
        setShowEmptyRow(false);
        setEditedDomain({});
      } catch (error) {
        console.error('Error saving changes:', error);
      }
    }
  };
  

  const cancelChanges = () => {
    setShowEmptyRow(false);
    setEditedDomain(null);
  };


  const handleInputChange = (event, field) => {
    const value = event.target.value;
    if (field === 'orderPayment') {
      const updatedOrderPayment = editedDomain.orderPayment === 'No' ? 'Yes' : 'No';
      setEditedDomain({ ...editedDomain, orderPayment: updatedOrderPayment });
    } else if (field === 'value') {
      // Update orderValue based on the new value input
      const orderValue = parseFloat(value); // Parse the input value to float
      setEditedDomain({ ...editedDomain, value: value, orderValue: orderValue });
    } else {
      setEditedDomain({ ...editedDomain, [field]: value });
    }
  };
  
  

 
   // Sort the domains by timestamp in descending order
   const sortedDomains = domains.slice().sort((a, b) => {
    // Assuming each domain entry has a timestamp field called 'timestamp'
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
    const isSmallScreen = window.innerWidth <= 768;

    const ordersToDisplay = sortedDomains.slice(startIndex, endIndex);

    
    const thStyle = {
      backgroundColor: '#f0f0f0',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '150px' : '200px',
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#f0f0f0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '100px' : '200px',
    };

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };

    const toggleRowExpansion = (domainId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [domainId]: !prevExpandedRows[domainId],
      }));
    };

    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
      textAlign: 'left',
  
    };

     // Media query to adjust styles for smaller screens
     if (window.innerWidth <= 768) {
      tdStyle.maxWidth = '100px'; // Adjust maxWidth for smaller screens
      thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
    }

    const expandbtnStyle = {
      margin: '5px',
      padding: '6px 12px',
      fontSize: '12px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: 'blue',
      color: 'white',
      cursor: 'pointer'
    };



    const NxtNavbuttonStyle = {

      position: 'absolute',
    
      right: '10px',
     
      
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'right', 
     
        margin: '0 5px', // Margin between buttons
        padding: '8px', // Padding inside buttons
        cursor: 'pointer', // Cursor style
        backgroundColor: 'transparent',
      };
    
      const PreNavbuttonStyle = {
    
        position: 'absolute',
    
      right: '40px',
     
      
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'right', 
     
        margin: '0 5px', // Margin between buttons
        padding: '8px', // Padding inside buttons
        cursor: 'pointer', // Cursor style
        backgroundColor: 'transparent',
      };
      
      const disabledNxtNavButtonStyle = {
        ...NxtNavbuttonStyle,
    
        cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
        color: 'grey', // Set text color to grey for disabled buttons
    
      alignItems: 'center',
      justifyContent: 'center',
      };
    
      const disabledPreNavButtonStyle = {
        ...PreNavbuttonStyle,
    
        cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
        color: 'grey', // Set text color to grey for disabled buttons
    
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      };
      
      
      const NaviconStyle = {
        marginRight: '5px', // Margin between icon and text
      };
    
      
      const handlePreviousPage = () => {
        const newStartIndex = Math.max(startIndex - 3, 0);
        setStartIndex(newStartIndex);
        setEndIndex(newStartIndex + 3);
      };
  
      const handleNextPage = () => {
        const newStartIndex = endIndex;
        const newEndIndex = Math.min(endIndex + 3, domains.length);
        setStartIndex(newStartIndex);
        setEndIndex(newEndIndex);
      };



  return (
    <div style={profileStyle}>
      {notification && <Notification message={notification} duration={2000} onClose={() => setNotification(null)} />}
      <div className="header-manage-domains">
        <div className="column-three-manage-domains">
          <Link to="/marketplace" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
            <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>Marketplace</button>
          </Link>
          <Link to="/dashboard" style={{ display: 'flex', alignItems: 'center', textDecoration: 'none', color: 'black' }}>
            <button style={{ marginRight: '15px', padding: '2px 4px', border: '1px solid black', borderRadius: '5px', background: 'transparent', cursor: 'pointer' }}>Dashboard</button>
          </Link>
          <h4><button style={{ margin: '5px', padding: '6px 12px', fontSize: '12px', border: 'none', borderRadius: '5px', backgroundColor: '#4f4f4f', color: 'white', cursor: 'pointer' }} onClick={handleLogout}>Logout</button></h4>
        </div>
      </div>
      <div>
        {isLoading && <LoadingSpinner />}
        {!isLoading && (
          <>
            <div>
            <h2>
              <button
  style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
  onClick={handlePreviousPage}
  disabled={startIndex <= 0}
>
  <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
  style={endIndex >= domains.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
  onClick={handleNextPage}
  disabled={endIndex >= domains.length}
>
  <Icon icon="carbon:next-filled" color={endIndex >= domains.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>

              </h2>
              
            </div>
         
            <div>
  
              {/* Table for input fields */}
              {!showEmptyRow && (
  <table style={tableStyle}>
    <thead>
      <tr>
        <th style={thStyle}>Name</th>
        <th style={thStyle}>Email</th>
        <th style={thStyle}>Phone</th>
        {isSmallScreen && <th style={thStyle}></th>}
        {!isSmallScreen && (
          <>
            <th style={thStyle}>Requirement</th>
            <th style={thStyle}>Description</th>
            <th style={thStyle}>Value (Rand)</th>
            <th style={thStyle}>Order fully paid?</th>
            <th style={thStyle}>Captured</th>
            <th style={thStyle}>Order date</th>
            <th style={thStyle}>Actions</th>
          </>
        )}
      </tr>
    </thead>
    <tbody>
      {ordersToDisplay.map((domain) => {
        const rows = [
          <tr key={domain.id}>
            <td style={tdStyle}>{domain.domainName}</td>
            <td style={tdStyle}>{domain.domainEmail}</td>
            <td style={tdStyle}>{domain.phone}</td>
            {isSmallScreen && (
              <td style={tdStyle}>
                <button style={expandbtnStyle} className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
                  {expandedRows[domain.id] ? '-' : '+'}
                </button>
              </td>
            )}
            {!isSmallScreen && (
              <>
                <td style={tdStyle}>{domain.requirement}</td>
                <td style={tdStyle}>{domain.description}</td>
                <td style={tdStyle}>{domain.orderValue}</td>
                <td style={tdStyle}>{domain.orderPayment}</td>
                <td style={tdStyle}>{domain.id}</td>
                <td style={tdStyle}>{domain.orderDate}</td>
                <td style={tdStyle}>
                  <button onClick={() => editDomain(domain.id)}>Edit</button>
                  <button onClick={() => removeDomain(domain.id)}>Remove</button>
                </td>
              </>
            )}
          </tr>
        ];

        if (isSmallScreen && expandedRows[domain.id]) {
          rows.push(
            <tr key={`${domain.id}-expanded`}>
              <td colSpan={isSmallScreen ? '3' : '10'}>
                <div>
                  <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
                    <strong>{domain.domainName}</strong>
                  </p>
                  <p><strong>Requirement:</strong> {domain.requirement}</p>
                  <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
                  <p><strong>Value:</strong> {domain.orderValue}</p>
                  <p><strong>Phone:</strong> {domain.orderPayment}</p>
                  <p><strong>Tracking ID:</strong> {domain.id}</p>
                  <p><strong>Date Captured:</strong> {domain.orderDate}</p>
                  <p><button onClick={() => editDomain(domain.id)}>Edit</button>
                  <button onClick={() => removeDomain(domain.id)}>Remove</button></p>
                </div>
              </td>
            </tr>
          );
        }

        return rows;
      })}
    </tbody>
  </table>
)}

              {/* Table for input fields */}
              {showEmptyRow && (
                <table>
                  <tbody>
                      <tr className="edit-input-row">
                      <td><input type="text" placeholder="Name" value={editedDomain.domainName} onChange={(e) => handleInputChange(e, 'domainName')} /></td>
                      <td><input type="email" placeholder="Email" value={editedDomain.domainEmail} onChange={(e) => handleInputChange(e, 'domainEmail')} /></td>
                      <td><input type="tel" placeholder="Phone" value={editedDomain.phone} onChange={(e) => handleInputChange(e, 'phone')} /></td>
                      <td>
                        <select value={editedDomain.requirement} onChange={(e) => handleInputChange(e, 'requirement')}>
                          <option value="">Select Requirement</option>
                          {requirementOptions.map(option => (
                            <option key={option} value={option}>{option}</option>
                          ))}
                        </select>
                      </td>
                      <td><textarea placeholder="Description" value={editedDomain.description} onChange={(e) => handleInputChange(e, 'description')} /></td>
                      <td><input type="number" placeholder="Value" value={editedDomain.value} onChange={(e) => handleInputChange(e, 'value')} /></td>
                      <td>
  {showEmptyRow || domains.id === editedDomain?.id ? (
    <div>
     
      
    </div>
  ) : (
    editedDomain.orderPayment
  )}
</td>

                      <td></td>
                      <td>{editedDomain.orderDate}</td>
                      <td>
                        <button onClick={saveChanges}>Save</button>
                        <button onClick={cancelChanges}>Cancel</button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              )}
            </div>
  
            {!showEmptyRow && <button onClick={addDomain}>Add Domain</button>}
          </>
        )}
      </div>
    </div>
  );
}  

export default Statistics;
