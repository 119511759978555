import React, { useState, useEffect } from 'react';
import { getDoc, doc, onSnapshot } from 'firebase/firestore';
import { dbFirestore, auth, signOut } from '../firebase';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';


const UserStatistics = ({ userUid }) => {

    const [currentWeekEarnings, setCurrentWeekEarnings] = useState('');
    const [currentMonthEarnings, setCurrentMonthEarnings] = useState('');
    const [previousMonthEarnings, setPreviousMonthEarnings] = useState('');
    const [previousWeekEarnings, setPreviousWeekEarnings] = useState('');
    const [paymentStatus, setpaymentStatus] = useState('');

    const { userId } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  
    useEffect(() => {
        const fetchUserEarnings = async () => {
          try {
            const docRef = doc(dbFirestore, 'account', userId);
            const docSnapshot = await getDoc(docRef);
            if (docSnapshot.exists()) {
              const userData = docSnapshot.data();
              const earnings = userData.Earnings || {};
      
              // Set previous week earnings
              const previousWeekEarnings = earnings.previousWeekEarnings || 0;
              setPreviousWeekEarnings(previousWeekEarnings);
      
              // Set previous month earnings
              const previousMonthEarnings = earnings.previousMonthEarnings || 0;
              setPreviousMonthEarnings(previousMonthEarnings);
      
              // Set current month earnings
              const currentMonthEarnings = earnings.currentMonthEarnings || 0;
              setCurrentMonthEarnings(currentMonthEarnings);
      
              // Set current week earnings
              const currentWeekEarnings = earnings.currentWeekEarnings || 0;
              setCurrentWeekEarnings(currentWeekEarnings);
            }
            setIsLoading(false);
          } catch (error) {
            console.error('Error fetching user earnings:', error);
            setIsLoading(false);
          }
        };

        fetchUserEarnings();
      
        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(doc(dbFirestore, 'account', userId), (docSnapshot) => {
          if (docSnapshot.exists()) {
            const userData = docSnapshot.data();
            const earnings = userData.Earnings || {};
      
            // Set previous week earnings
            const previousWeekEarnings = earnings.previousWeekEarnings || 0;
            setPreviousWeekEarnings(previousWeekEarnings);
      
            // Set previous month earnings
            const previousMonthEarnings = earnings.previousMonthEarnings || 0;
            setPreviousMonthEarnings(previousMonthEarnings);
      
            // Set current month earnings
            const currentMonthEarnings = earnings.currentMonthEarnings || 0;
            setCurrentMonthEarnings(currentMonthEarnings);
      
            // Set current week earnings
            const currentWeekEarnings = earnings.currentWeekEarnings || 0;
            setCurrentWeekEarnings(currentWeekEarnings);
          }
        });

        

    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [userId]);
  

  return (
    <div>
     
        <div>
          {currentWeekEarnings}
        </div>
     
    </div>
  );
};

export default UserStatistics;
