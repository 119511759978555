import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { auth, signOut } from '../firebase';
import "./Website.css";
import { Icon } from '@iconify/react';
import LoadingSpinner from '../LoadingSpinner';

const Website = () => {
  const externalUrl = "https://www.websiteservice.co.za"; // Define the URL here
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // Initialize useNavigate
  const [notification, setNotification] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { userId } = useParams();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  

  const openExternalWebsite = () => {
    window.open(externalUrl, '_blank');
  };

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

  
   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  useEffect(() => {
    const handleOnlineStatusChange = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', handleOnlineStatusChange);
    window.addEventListener('offline', handleOnlineStatusChange);

    return () => {
      window.removeEventListener('online', handleOnlineStatusChange);
      window.removeEventListener('offline', handleOnlineStatusChange);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 3000); // Set a loading timeout if the page takes too long to load

    return () => clearTimeout(timer);
  }, []);

  const handleRefresh = () => {
    window.location.reload();
  };


  const buttonRtnStyle = {
    background: 'none',
    border: 'none', // Remove border if needed
  };

  const textStyle = {
    color: '#333', // Text color
    fontSize: '18px', // Text size
    verticalAlign: 'middle', // Align text vertically with icon
  };

  const iconStyle = {
    color: '#007bff', // Icon color
    marginRight: '5px', // Adjust icon spacing
    verticalAlign: 'middle', // Align icon vertically with text
  }; 

  return (
    <div className="external-website">
      <div className="sticky-header">
        <Link to={`/profile/${userId}`}>
          <button style={buttonRtnStyle}>
            <Icon icon="ion:arrow-back-circle-outline" width="24" height="24" style={iconStyle} />
            <span style={textStyle}>Return to Profile</span>
          </button>
        </Link>
      </div>

      {isLoading && <LoadingSpinner />}

      {!isLoading && isOnline ? (
        <iframe
          title="External Website"
          src={externalUrl}
          style={{ width: '100%', height: '100vh', border: 'none', overflow: 'hidden' }}
          allowFullScreen
        />
      ) : (
        <div style={{ textAlign: 'center', marginTop: '20px' }}>
          <h2>No Internet Connection</h2>
          <p>Please check your internet connection and try again.</p>
          <button onClick={handleRefresh} style={{ marginTop: '10px' }}>
            Refresh
          </button>
        </div>
      )}
    </div>
  );
};

 

export default Website;
