 // src/components/authentication/SignIn.js

 import React, { useState, useEffect } from "react";
 import { Link } from "react-router-dom";
 import { signInWithEmailAndPassword, signInWithPhoneNumber } from "firebase/auth";

 import { useNavigate } from "react-router-dom";
 import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
 import { doc, getDoc, setDoc } from 'firebase/firestore';
 import { v4 as uuidv4 } from 'uuid';
 import { dbFirestore } from '../../firebase';
 import { Timestamp } from 'firebase/firestore';
 import { useParams } from 'react-router-dom';
 import "./SignIn.css";
 import { Icon } from '@iconify/react';
 
 
 function SignIn() {
   const [email, setEmail] = useState("");
   const [password, setPassword] = useState("");
   const [user, setUser] = useState(null);
   const { userId } = useParams();
   const auth = getAuth();
   const [error, setError] = useState(null); // Initialize error state
   const navigate = useNavigate(); // Initialize useNavigate
 


   const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const { user } = result;

      // Check if the user account is disabled
    if (user.disabled) {
      console.error("User account is disabled.");
      // Handle the case where the user account is disabled
      return;
    }

      // Generate a unique 8-character code using uuid
      const uniqueCode = uuidv4().slice(0, 8);


      // Get the current timestamp
      const timestamp = Timestamp.now();


      // Check if the user document already exists in Firestore
      const userDocSnapshot = await getDoc(userDocRef);

      // Save the unique code to the Firestore database
      const userDocRef = doc(dbFirestore, 'account', user.uid);
      await setDoc(userDocRef, { uniqueCode, createdAt: timestamp }, { merge: true });

      navigate(`/profile/${user.uid}`);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };


  
   const handleSubmit = async (e) => {
     e.preventDefault();
     try {
      
       // Sign in with email and password
    const result = await signInWithEmailAndPassword(auth, email, password);
    const { user } = result;

    // Check if the user account is disabled
    if (user.disabled) {
      console.error("User account is disabled.");
      // Handle the case where the user account is disabled
      setError("User account is disabled. Please contact support.");
      return;
    }
    
       // Redirect to Profile.js after successful sign-in
       
       navigate(`/profile/${user.uid}`);
       // You can use react-router for this navigation
       // Example: history.push("/profile"); 
     } catch (error) {
      console.error("Firebase Error:", error);

       
      // Set a custom error message based on the Firebase error code
      let errorMessage = "An error occurred during sign-in.";
      if (error.code === "auth/user-not-found") {
        errorMessage = "User not found. Please check your email.";
      } else if (error.code === "auth/wrong-password") {
        errorMessage = "Incorrect password. Please try again.";
      }


      setError(errorMessage);
    }
   };

   const handleEmailInput = () => {
    // Clear the error message when the user interacts with the email input
    setError(null);
  };

  const handlePasswordInput = () => {
    // Clear the error message when the user interacts with the password input
    setError(null);
  };



  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  
    // Make sure to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, []);

  /* take a user to profile when accessing page (if they are logged in)
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, redirect to profile page
        navigate(`/profile/${user.uid}`);
      } else {
        setUser(null);
      }
    });
  
    // Make sure to unsubscribe when the component unmounts
    return () => unsubscribe();
  }, [auth, navigate]);
  */
 
  const signinbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };

  const NaviconStyle = {
    marginRight: '5px', // Margin between icon and text
  };
 
   return (
     
    <main className="main">
    <div className="sign-in-parent-container">
      <div className="header-account-settings">
        <h2>Welcome to our affiliate program </h2>
        
        <div class="icon-with-text">
        <Icon icon="akar-icons:info-fill" color="black" width="17" height="17" style={NaviconStyle}/> 
        <a href="https://www.websiteservice.co.za/affiliate-program/" target="_blank" rel="noopener noreferrer" class="styled-link">Information</a>

        </div>
                        <p></p>
        <div className="card">
          <h2>Sign In</h2>            
     
     <form name="signin" className="form" onSubmit={handleSubmit}>

     <div className="method-control">
              {/* Use a function to open Google sign-in popup not ready with unique code, created At, profile missing.
              <button onClick={handleGoogleSignIn} className="method-action">
              <Icon icon="flat-color-icons:google" width="24" height="24" className="icon-before"/>
              <span className="button-text">Sign in with Google</span>
              
              </button>
            </div>

            <div className="striped">
            <span className="striped-line"></span>
            <span className="striped-text">Or</span>*/}
            <span className="striped-line"></span>
          </div>
             <div className="sign-in-input-control">
               <label htmlFor="email" className="input-label" hidden>
                 Email Address
               </label>
               <input
                 type="email"
                 name="email"
                 id="email"
                 className="input-field"
                 placeholder="Email Address"
                 value={email}
                 onChange={(e) => setEmail(e.target.value)}
                 onFocus={handleEmailInput} // Clear error on focus required
               />
             </div>
             <div className="sign-in-input-control">
               <label htmlFor="password" className="input-label" hidden>
                 Password
               </label>
               <input
                 type="password"
                 name="password"
                 id="password"
                 className="input-field"
                 placeholder="Password"
                 value={password}
                 onChange={(e) => setPassword(e.target.value)}
                 onFocus={handlePasswordInput} // Clear error on focus required
               />
             </div>
             <div className="input-control">
               <Link to="/reset-password" className="text text-links">
                 Forgot Password
               </Link>
               <input
                 type="submit"
                 name="submit"
                 className="input-submit"
                 value="Sign In"
                 // Enable the submit button only when email and password are not empty
                 disabled={!email || !password}
               />
             </div>
           </form>
           {/* Display the error message if it exists */}
      {error && <div className="error-message">{error}</div>}
           <div className="striped">
             <span className="striped-line"></span>
             <span className="striped-text">Or</span>
             <span className="striped-line"></span>
           </div>
           <div className="method">
           
             <div className="method-control">
               <p className="text text-normal">
                 New user? <span><Link to="/sign-up" className="text text-links">Create an account</Link></span>
               </p>
             </div>
            
           </div>
           </div>
       
      </div>
      
      </div>
    </main>
   );
 }
 
 export default SignIn;