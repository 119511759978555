// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signOut, updatePassword, sendEmailVerification } from "firebase/auth";
import { getFirestore } from 'firebase/firestore';
import { getStorage } from "firebase/storage";



// Initialize Firebase
const firebaseConfig = {
    apiKey: "AIzaSyBeamSUn5GJ-055N9u9b9wlyTvmk2zcOcw",
    authDomain: "web-affiliate-program.firebaseapp.com",
    projectId: "web-affiliate-program",
    storageBucket: "web-affiliate-program.appspot.com",
    messagingSenderId: "859011569251",
    appId: "1:859011569251:web:f8220bc9a9fcbfecb70a56",
    measurementId: "G-PECWS5S80B"
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Initialize auth with the app instance
const dbFirestore = getFirestore(app); // Initialize dbFirestore with the app instance
const storage = getStorage(app);


export { auth, dbFirestore, storage, onAuthStateChanged, signOut, updatePassword, sendEmailVerification }; // Export auth and dbFirestore directly
export default app;