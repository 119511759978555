import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { auth } from "../../firebase";
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { dbFirestore } from '../../firebase';
import { createUserWithEmailAndPassword, signInWithPopup, GoogleAuthProvider } from "firebase/auth";
import { useParams } from 'react-router-dom';
import { Timestamp } from 'firebase/firestore';
import { Icon } from '@iconify/react';
import "./SignUp.css";

function generateUniqueCode() {
  const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const numbers = '0123456789';

  let code = '';
  
  // Generate 3 random capital letters
  for (let i = 0; i < 3; i++) {
    code += letters.charAt(Math.floor(Math.random() * letters.length));
  }
  
  // Generate 3 random numbers
  for (let i = 0; i < 3; i++) {
    code += numbers.charAt(Math.floor(Math.random() * numbers.length));
  }

  return code;
}

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState(null); 
  const [confirmPasswordError, setConfirmPasswordError] = useState("");
  const [user, setUser] = useState(null);
  const [payCycle, setPayCycle] = useState('');
  const navigate = useNavigate(); 
  const { userId } = useParams();

  const handleGoogleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const { user } = result;

      const uniqueCode = generateUniqueCode();
      const timestamp = Timestamp.now();

      const userDocRef = doc(dbFirestore, 'account', user.uid);
      
      await setDoc(userDocRef, { uniqueCode, createdAt: timestamp, payCycle, isEmailVerified: false }, { merge: true });

      navigate(`/account-settings/${user.uid}`);
    } catch (error) {
      console.error("Error signing in with Google:", error);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    setError(null);

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    try {
      await createUserWithEmailAndPassword(auth, email, password);
      const user = auth.currentUser;
  
      const uniqueCode = generateUniqueCode();
      const timestamp = Timestamp.now();

      const userDocRef = doc(dbFirestore, 'account', user.uid);
      await setDoc(userDocRef, { uniqueCode, createdAt: timestamp, payCycle, isEmailVerified: false }, { merge: true });

      
      navigate(`/account-settings/${user.uid}`);
    } catch (error) {
      console.error("Error signing up:", error);
  
      let errorMessage = "An error occurred during sign-up.";
      if (error.code === "auth/email-already-in-use") {
        errorMessage = "Email is already in use. Please choose a different one.";
      } else if (error.code === "auth/invalid-email") {
        errorMessage = "Invalid email address. Please check your email.";
      } else if (error.code === "auth/weak-password") {
        errorMessage = "Password is too weak. Please choose a stronger one.";
      }
  
      setError(errorMessage);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      } else {
        setUser(null);
      }
    });
  
    return () => unsubscribe();
  }, []);

  const inputsubmitStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };

  const NaviconStyle = {
    marginRight: '5px', // Margin between icon and text
  };

  return (
    <main className="main">
      <div className="sign-in-parent-container">
      <div className="header-account-settings">

        <h2>Welcome to our affiliate program
      
        </h2>
        
        <div class="icon-with-text">
        <Icon icon="akar-icons:info-fill" color="black" width="17" height="17" style={NaviconStyle}/> 
        <a href="https://www.websiteservice.co.za/affiliate-program/" target="_blank" rel="noopener noreferrer" class="styled-link">Information</a>

        </div>
                        <p></p>
        <div className="card">
            <h2>Sign Up</h2>
            
            <form name="signup" className="form" onSubmit={handleSubmit}>
              
              <div className="method-control">
                {/* Use a function to open Google sign-in popup not ready with unique code, created At, profile missing.
                <button onClick={handleGoogleSignIn} className="method-action">
                  <Icon icon="flat-color-icons:google" width="24" height="24" className="icon-before"/>
                  <span className="button-text">Sign in with Google</span>
                </button>
              </div>
              <div className="striped">
                <span className="striped-line"></span>
                <span className="striped-text">Or</span>*/}
                <span className="striped-line"></span>
              </div>
              <div className="sign-up-input-control">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="input-field"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="sign-up-input-control">
                <input
                  type="password"
                  name="password"
                  id="password"
                  className="input-field"
                  placeholder="Password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="sign-up-input-control">
                <input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  className="input-field"
                  placeholder="Confirm Password"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                />
              </div>
              <div className="sign-up-input-control">
                <select
                  name="payCycle"
                  id="payCycle"
                  className="input-field"
                  value={payCycle}
                  onChange={(e) => setPayCycle(e.target.value)}
                  required
                >
                  <option value="">Select Pay Cycle</option>
                  <option value="Weekly">Weekly</option>
                  <option value="Monthly">Monthly</option>
                </select>
              </div>
              <div className="">
                <input
                  type="submit"
                  name="submit"
                  className="input-submit"
                  value="Sign Up for Affiliate Program"
                  disabled={!email || !password || !confirmPassword || !payCycle || password !== confirmPassword}
                />
              </div>
            </form>    
            {error && <div className="error-message">{error}</div>}
            <div className="method">
              <div className="method-control">
                <p className="text text-normal">
                  Already a User? <span><Link to="/sign-in" className="text text-links">Sign In to your account</Link></span>
                </p>
              </div>
            </div>
          </div>
        
    </div>
        
      </div>
    </main>
  );
}

export default SignUp;
