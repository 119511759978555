import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, doc, getDoc, onSnapshot, Timestamp } from 'firebase/firestore';
import { auth, dbFirestore, sendEmailVerification, signOut, storage } from '../../firebase';
import { getDownloadURL, ref } from 'firebase/storage';
import { Link } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Tooltip from '../Tooltip';
import { Icon } from '@iconify/react';
import LoadingSpinner from '../../LoadingSpinner';
import "./Profile.css";


const Profile = ({ onClose }) => {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const navigate = useNavigate();
  const [userDomains, setUserDomains] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState({});

  const [fullName, setFullName] = useState('');
  const [createdAt, setCreatedAt] = useState('');
  const [uniqueCode, setUniqueCode] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [payCycle, setpayCycle] = useState('');
  const [currentWeekEarnings, setCurrentWeekEarnings] = useState('');
  const [currentMonthEarnings, setCurrentMonthEarnings] = useState('');
  const [previousMonthEarnings, setPreviousMonthEarnings] = useState('');
  const [previousWeekEarnings, setPreviousWeekEarnings] = useState('');
  const [paymentStatus, setpaymentStatus] = useState('');

  const [profilePictureURL, setProfilePictureURL] = useState('');
  const [coverPhotoURL, setCoverPhotoURL] = useState('');

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(3);

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);

   // Function to handle user logout
   const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/sign-in'); // Redirect to the login page after logout
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };


  useEffect(() => {
    const fetchProfilePictureURL = async () => {
      try {
        const docRef = doc(dbFirestore, 'users', userId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const profilePictureRef = ref(storage, `profilePictures/${userId}`);
          const url = await getDownloadURL(profilePictureRef);
          setProfilePictureURL(url);
        }
      } catch (error) {
        console.error('Error fetching profile picture URL:', error);
      }
    };

    fetchProfilePictureURL();
  }, [userId]);
  
  

  useEffect(() => {
    const docRef = doc(dbFirestore, 'account', userId);

    getDoc(docRef)
      .then((docSnapshot) => {
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          setFullName(userData.fullName || '');
          setCreatedAt(userData.createdAt || '');
          setUniqueCode(userData.uniqueCode || '');
          setpayCycle(userData.payCycle || '');
          setpaymentStatus(userData.paymentStatus || '');
          setProfilePictureURL(userData.profilePictureURL || '');
          setCoverPhotoURL(userData.coverPhotoURL || '');


          // Convert Firestore Timestamp to JavaScript Date object
        const createdAtTimestamp = userData.createdAt;
        const createdAtDate = createdAtTimestamp.toDate();

        // Format the date as "Month Year"
        const monthYear = createdAtDate.toLocaleString('default', { month: 'long' }) + ' ' + createdAtDate.getFullYear();
        setCreatedAt(monthYear);
          
        
      }
        
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error getting document:', error);
        setIsLoading(false);
      });
  }, [userId]);

  // Function to extract month and year from createdAt field
  const extractMonthYear = (createdAt) => {
    if (!createdAt) return '';

    const date = new Date(createdAt);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  const memberSince = extractMonthYear(createdAt);

  const copyToClipboard = () => {
    navigator.clipboard.writeText(uniqueCode);
    setIsCopied(true);
  };


  useEffect(() => {
    const fetchUserEarnings = async () => {
      try {
        const docRef = doc(dbFirestore, 'account', userId);
        const docSnapshot = await getDoc(docRef);
        if (docSnapshot.exists()) {
          const userData = docSnapshot.data();
          const earnings = userData.Earnings || {};
  
          // Set previous week earnings
          const previousWeekEarnings = earnings.previousWeekEarnings || 0;
          setPreviousWeekEarnings(previousWeekEarnings);
  
          // Set previous month earnings
          const previousMonthEarnings = earnings.previousMonthEarnings || 0;
          setPreviousMonthEarnings(previousMonthEarnings);
  
          // Set current month earnings
          const currentMonthEarnings = earnings.currentMonthEarnings || 0;
          setCurrentMonthEarnings(currentMonthEarnings);
  
          // Set current week earnings
          const currentWeekEarnings = earnings.currentWeekEarnings || 0;
          setCurrentWeekEarnings(currentWeekEarnings);
        }
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user earnings:', error);
        setIsLoading(false);
      }
    };
  
    fetchUserEarnings();
  
    // Subscribe to real-time updates
    const unsubscribe = onSnapshot(doc(dbFirestore, 'account', userId), (docSnapshot) => {
      if (docSnapshot.exists()) {
        const userData = docSnapshot.data();
        const earnings = userData.Earnings || {};
  
        // Set previous week earnings
        const previousWeekEarnings = earnings.previousWeekEarnings || 0;
        setPreviousWeekEarnings(previousWeekEarnings);
  
        // Set previous month earnings
        const previousMonthEarnings = earnings.previousMonthEarnings || 0;
        setPreviousMonthEarnings(previousMonthEarnings);
  
        // Set current month earnings
        const currentMonthEarnings = earnings.currentMonthEarnings || 0;
        setCurrentMonthEarnings(currentMonthEarnings);
  
        // Set current week earnings
        const currentWeekEarnings = earnings.currentWeekEarnings || 0;
        setCurrentWeekEarnings(currentWeekEarnings);
      }
    });
  
    // Clean up the listener on unmount
    return () => unsubscribe();
  }, [userId]);
  
  

  useEffect(() => {
    const fetchUserDomains = async () => {
      try {
        const userDomainsRef = collection(dbFirestore, 'users', userId, 'domains');
        const userDomainsQuery = query(userDomainsRef);
        const userDomainsSnapshot = await getDocs(userDomainsQuery);
        const userDomainsData = userDomainsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserDomains(userDomainsData);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching user domains:', error);
        setIsLoading(false);
      }
    };

    fetchUserDomains();
  }, [userId]);

  const renderDomainList = () => {
  
    // Sort the domains by timestamp in descending order
  const sortedDomains = userDomains.slice().sort((a, b) => {
    // Assuming each domain entry has a timestamp field called 'timestamp'
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
    const isSmallScreen = window.innerWidth <= 768;

    const domainsToDisplay = sortedDomains.slice(startIndex, endIndex);

    
    const thStyle = {
      backgroundColor: '#f0f0f0',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '150px' : '200px',
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#f0f0f0',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '100px' : '200px',
    };

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };

    const toggleRowExpansion = (domainId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [domainId]: !prevExpandedRows[domainId],
      }));
    };

    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
      textAlign: 'left',
  
    };

     // Media query to adjust styles for smaller screens
     if (window.innerWidth <= 768) {
      tdStyle.maxWidth = '100px'; // Adjust maxWidth for smaller screens
      thStyle.maxWidth = '150px'; // Adjust maxWidth for smaller screens
    }

    


    return (

      
      <table style={tableStyle}>
       
        <thead>
          <tr>
            <th style={thStyle}>Name</th>
            <th style={thStyle}>Totally paid?</th>
            <th style={thStyle}>Value</th>
            {isSmallScreen && <th style={thStyle}></th>}
            {!isSmallScreen && (
              <>
                <th style={thStyle}>Phone</th>
                <th style={thStyle }>Requirement</th>
                <th style={thStyle}>Description</th>
                <th style={thStyle}>Date Captured</th>
                <th style={thStyle}>Method</th>
                <th style={thStyle}>Tracking ID</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          
        {domainsToDisplay.length > 0 ? (
  domainsToDisplay.map((domain, index) => {
    const rows = [
      <tr key={domain.id}>
        <td style={tdStyle}>{domain.domainName}</td>
        <td style={tdStyle}>{domain.orderPayment}</td>
        <td style={tdStyle}>R{domain.orderValue || 0}</td>
        {isSmallScreen && (
          <td style={tdStyle}>
            <button style={expandbtnStyle} className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
              {expandedRows[domain.id] ? '-' : '+'}
            </button>
          </td>
        )}
        {!isSmallScreen && (
          <>
            <td style={tdStyle}>{domain.phone}</td>
            <td style={tdStyle}>{domain.requirement}</td>
            <td style={{ padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#f0f0f0', overflow: 'visible', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px' }}>
              <Tooltip text={domain.description || 'No description is provided'}>
                <button style={TooltipbtnStyle}>Read description</button>
              </Tooltip>
            </td>
            <td style={tdStyle}>{domain.orderDate}</td>
            <td style={tdStyle}>{domain.captureType}</td>
            <td style={tdStyle}>{domain.id}</td>
          </>
        )}
      </tr>
    ];

    if (isSmallScreen && expandedRows[domain.id]) {
      rows.push(
        <tr key={`${domain.id}-expanded`}>
          <td colSpan={isSmallScreen ? '3' : '8'}>
            <div>
              <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
                <strong>{domain.domainName}</strong>
              </p>
              <p><strong>Phone:</strong> {domain.phone}</p>
              <p><strong>Requirement:</strong> {domain.requirement}</p>
              <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
              <p><strong>Date Captured:</strong> {domain.orderDate}</p>
              <p><strong>Method:</strong> {domain.captureType}</p>
              <p><strong>Tracking ID:</strong> {domain.id}</p>
            </div>
          </td>
        </tr>
      );
    }

    return rows;
  })
) : (
  <tr>
    <td colSpan={isSmallScreen ? '4' : '10'} style={{ textAlign: 'center' }}><h3>No customer used your Referral code as of now</h3></td>
  </tr>
)}

        </tbody>
      </table>


    );
  };

 

  const containerStyle = {
    backgroundColor: '#fff',
    padding: '8px',
    textAlign: 'left',
    borderBottom: '1px solid #ddd',
    borderRadius: '10px',
    
    
  };

  const greyIconClass = (value) => {
    return value ? '' : 'greyIcon';
  };

  const topcoverStyle = {
    background: `url(${process.env.PUBLIC_URL}/fallback-cover-photo.png)`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '95%', // Set the width to 100% of the viewport width
    height: '190px', // Set the desired height for your background cover
    borderRadius: '5px',
    position: 'relative',
    top: '8vw', // Shift the element down by 50% of its own height
    transform: 'translateY(-60%)', // Correct the element's position by moving it up by 50% of its own height
    margin: '0 auto',
   
  };

  const BigclosePopupStyle = {
    position: 'fixed', 
    top: '4%', // Adjust the top position according to your design
    right: '25%', // Adjust the right position according to your design
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    background: 'white',
    color: 'blue',
  };

  const SmallclosePopupStyle = {
    alignItems: 'right',
    textAlign: 'right',
    cursor: 'pointer', // Add a pointer cursor to indicate it's clickable
    background: 'white',
    color: 'blue',
  };

  const titleStyle = {
    marginLeft: '3%',
  };

  const handleCopyClick = () => {
    copyToClipboard();
  };
  
  const copyStyle = {
  cursor: 'pointer',

  };
  
  const logoutStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: '#4f4f4f',
    color: 'white',
    cursor: 'pointer'
  };

  const settingsbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };

  const expandbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };


  const NxtNavbuttonStyle = {

    position: 'absolute',
  
    right: '10px',
   
    
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right', 
   
      margin: '0 5px', // Margin between buttons
      padding: '8px', // Padding inside buttons
      cursor: 'pointer', // Cursor style
      backgroundColor: 'transparent',
    };
  
    const PreNavbuttonStyle = {
  
      position: 'absolute',
  
    right: '40px',
   
    
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right', 
   
      margin: '0 5px', // Margin between buttons
      padding: '8px', // Padding inside buttons
      cursor: 'pointer', // Cursor style
      backgroundColor: 'transparent',
    };
    
    const disabledNxtNavButtonStyle = {
      ...NxtNavbuttonStyle,
  
      cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
      color: 'grey', // Set text color to grey for disabled buttons
  
    alignItems: 'center',
    justifyContent: 'center',
    };
  
    const disabledPreNavButtonStyle = {
      ...PreNavbuttonStyle,
  
      cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
      color: 'grey', // Set text color to grey for disabled buttons
  
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    };
    
    
    const NaviconStyle = {
      marginRight: '5px', // Margin between icon and text
    };
  
    
    const handlePreviousPage = () => {
      const newStartIndex = Math.max(startIndex - 3, 0);
      setStartIndex(newStartIndex);
      setEndIndex(newStartIndex + 3);
    };

    const handleNextPage = () => {
      const newStartIndex = endIndex;
      const newEndIndex = Math.min(endIndex + 3, userDomains.length);
      setStartIndex(newStartIndex);
      setEndIndex(newEndIndex);
    };
    

  return (

    <div>
  
    
      {isLoading && <LoadingSpinner />}
  
  {!isLoading && (
    
    <>
    <div className="section1-profile-cover">
   <div className="big-screen-button">
          
        </div>
      
    <div className="profile-top-cover" style={topcoverStyle}>
    
        </div>
        </div>
      <div className="section2-user-info">
    <div className="section-label">
      <div className="main-menu-profile">
      <div className="column-one-profile">
  
      <img
    src={profilePictureURL || process.env.PUBLIC_URL + '/fallback-profile-picture.png'}
    alt="Profile Picture"
    className="profile-picture"
    
  />
  
      </div>
  
      <div className="column-two-profile">
      <div className="small-screen-button">
          
        </div>
      <h3>{fullName ? fullName : 'User'}</h3>
      
      <div onClick={handleCopyClick} disabled={isCopied} style={copyStyle}> Referral code   {uniqueCode} {isCopied ? <Icon icon="solar:copy-bold-duotone" width="20" height="20" style={copyStyle} /> : <Icon icon="solar:copy-line-duotone" width="20" height="20" style={copyStyle} />}</div>
      <p><Link to={`/how-to-earn/${userId}`} >
            How to earn
          </Link> / <Link to={`/website/${userId}`} >
            Website
          </Link>

</p>
      <p> <Link to={`/account-settings/${userId}`} >
  <button style={ settingsbtnStyle }>
    Settings
  </button>
</Link>
<button style={ logoutStyle } onClick={handleLogout}>Logout</button>
    
          </p>
      
      </div>
    
      <div className="column-three-profile">
      <p className="profile-chat-contact">
  <div>
    {payCycle === 'Weekly' ? (
      <>
        Current Week Earnings: R{currentWeekEarnings}
      </>
    ) : payCycle === 'Monthly' ? (
      <>
        Current Month Earnings: R{currentMonthEarnings}
      </>
    ) : null}
  </div>
</p>

<p className="profile-tel-contact">
  <div>
    {payCycle === 'Weekly' ? (
      <>
        Previous Week Earnings: R{previousWeekEarnings}
      </>
    ) : payCycle === 'Monthly' ? (
      <>
        Previous Month Earnings: R{previousMonthEarnings}
      </>
    ) : null}
  </div>
</p>
<p className="profile-mail-contact">
<div>
    {payCycle === 'Weekly' ? (
      <>
        Previous Week Payout: R{paymentStatus !== '' ? paymentStatus : '0'}
      </>
    ) : payCycle === 'Monthly' ? (
      <>
        Previous Month Payout: R{paymentStatus !== '' ? paymentStatus : '0'}
      </>
    ) : null}
  </div>
 
  </p>
  
        </div>
        </div>
        </div>
        </div>
        <div className="section3-domain-portfolio">
          
        <h3 style={titleStyle}>Affiliate portfolio
        <button
  style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
  onClick={handlePreviousPage}
  disabled={startIndex <= 0}
>
  <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
  style={endIndex >= userDomains.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
  onClick={handleNextPage}
  disabled={endIndex >= userDomains.length}
>
  <Icon icon="carbon:next-filled" color={endIndex >= userDomains.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>
        
        </h3>
       


         
              {renderDomainList()}
        
        </div>
        </>
    )}
      </div>
     
     
    
      
    );
  };



export default Profile;
