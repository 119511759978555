import React, { useState, useEffect } from 'react';
import LoadingSpinner from '../../LoadingSpinner';
import { collection, getDocs, doc, updateDoc, onSnapshot, query, where } from 'firebase/firestore';
import { dbFirestore, auth, signOut } from '../../firebase';
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import Tooltip from '../Tooltip';

function AdminAll() {
  
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [allDomains, setAllDomains] = useState([]);

  const [expandedRows, setExpandedRows] = useState({});

  const [startIndex, setStartIndex] = useState(0);
  const [endIndex, setEndIndex] = useState(3);

  const { userId } = useParams();

  const [user, setUser] = useState(null);



  const navigate = useNavigate();

  useEffect(() => {
    // Listen for authentication state changes
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        // User is signed in, update the user state
        setUser(user);
      } else {
        // User is signed out, redirect to sign-in page
        navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
      }
    });

    // Clean up the subscription to avoid memory leaks
    return () => unsubscribe();
  }, [navigate]);
  
     // Function to handle user logout
     const handleLogout = async () => {
      try {
        await signOut(auth);
        navigate('/sign-in'); // Redirect to the login page after logout
      } catch (error) {
        console.error('Error logging out:', error);
      }
    };

  useEffect(() => {
    const fetchAllDomains = async () => {
      try {
        const publicDomainsRef = collection(dbFirestore, 'domains');
        const publicQuerySnapshot = await getDocs(publicDomainsRef);
        const publicDomainsData = publicQuerySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const allUserDomains = [];
        const usersRef = collection(dbFirestore, 'users');
        const userQuerySnapshot = await getDocs(usersRef);
        const userDocs = userQuerySnapshot.docs;

        for (const userDoc of userDocs) {
          const userUid = userDoc.id;
          const userDomainsRef = collection(dbFirestore, 'users', userUid, 'domains');
          const userQuerySnapshot = await getDocs(userDomainsRef);
          const userDomainsData = userQuerySnapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          allUserDomains.push(...userDomainsData);
        }

        const allDomains = [...publicDomainsData, ...allUserDomains];
        setAllDomains(allDomains);
        setIsLoading(false);
      } catch (error) {
        console.error('Error fetching domains:', error);
        setIsLoading(false);
      }
    };

    fetchAllDomains();
  }, []);

  const profileStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '15px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };

  const renderDomainList = () => {

    // Sort the domains by timestamp in descending order
  const sortedDomains = allDomains.slice().sort((a, b) => {
    // Assuming each domain entry has a timestamp field called 'timestamp'
    return new Date(b.timestamp) - new Date(a.timestamp);
  });
    const isSmallScreen = window.innerWidth <= 768;

    const domainsToDisplay = sortedDomains.slice(startIndex, endIndex);

    
    const thStyle = {
      backgroundColor: '#f0f0f0',
      padding: '8px',
      textAlign: 'left',
      borderBottom: '1px solid #ddd',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '150px' : '200px',
    };

    const tdStyle = {
      padding: '8px',
      borderBottom: '1px solid #ddd',
      backgroundColor: '#ffffff',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: isSmallScreen ? '100px' : '200px',
    };

    const TooltipbtnStyle = {
      backgroundColor: '#eeeeee',
      color: '#000000',
      padding: '2px 4px', // Adjust padding as needed for your content
      border: 'none',
      borderRadius: '5px', // Border radius for rounded corners
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center', // Center content vertically
      justifyContent: 'center', // Center content horizontally
     
      boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
    };

    const toggleRowExpansion = (domainId) => {
      setExpandedRows((prevExpandedRows) => ({
        ...prevExpandedRows,
        [domainId]: !prevExpandedRows[domainId],
      }));
    };

    const tableStyle = {
      borderCollapse: 'collapse',
      width: '100%',
      textAlign: 'left',
  
    };

    const expandbtnStyle = {
        margin: '5px',
        padding: '6px 12px',
        fontSize: '12px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: 'blue',
        color: 'white',
        cursor: 'pointer'
      };

    return (

  
      <table style={tableStyle}>
       
        <thead>
          <tr>
            <th style={thStyle}>Order no.</th>
            <th style={thStyle}>Name</th>
            <th style={thStyle}>Email</th>
            {isSmallScreen && <th style={thStyle}></th>}
            {!isSmallScreen && (
              <>
                <th style={thStyle}>Phone</th>
                <th style={thStyle }>Requirement</th>
                <th style={thStyle}>Description</th>
                <th style={thStyle}>Value</th>
                <th style={thStyle}>Payment</th>
                <th style={thStyle}>Capture Method</th>
                <th style={thStyle}>Order Date</th>
                <th style={thStyle}>Profile</th>
                <th style={thStyle}>Doc ID</th>
              </>
            )}
          </tr>
        </thead>
        <tbody>
          {domainsToDisplay.map((domain, index) => {
            const actualIndex = startIndex + index + 1;
            const rows = [
              <tr key={domain.id}>
                <td style={tdStyle}>{actualIndex}</td>
                <td style={tdStyle}>{domain.domainName}</td>
                <td style={tdStyle}>{domain.domainEmail}</td>
                <td style={tdStyle}>{domain.phone}</td>

                {isSmallScreen && (
                  <td style={tdStyle}>
                    <button style={ expandbtnStyle } className="expand-button" onClick={() => toggleRowExpansion(domain.id)}>
                      {expandedRows[domain.id] ? '-' : '+'}
                    </button>
                  </td>
                )}
                {!isSmallScreen && (
                  <>
                    
                    <td style={tdStyle}>{domain.requirement}</td>
                    <td style={{ padding: '8px', borderBottom: '1px solid #ddd', backgroundColor: '#ffffff', overflow: 'visible', textOverflow: 'ellipsis', whiteSpace: 'nowrap', maxWidth: '200px', }}>
                      <Tooltip text={domain.description || 'No description is provided'}>
                        <button style={TooltipbtnStyle}>Read description</button>
                      </Tooltip>
                    </td>
                    <td style={tdStyle}>{domain.orderValue} </td>
                    <td style={tdStyle}>{domain.orderPayment}</td>
                    <td style={tdStyle}>{domain.capture}</td>
                    <td style={tdStyle}>{domain.orderDate}</td>
                    <td style={tdStyle}>{domain.orderPayment}</td>
                    <td style={tdStyle}>{domain.id}</td>
                    
                
                  </>
                )}
              </tr>
            ];
  
            if (isSmallScreen && expandedRows[domain.id]) {
              rows.push(
                <tr key={`${domain.id}-expanded`}>
                  <td colSpan={isSmallScreen ? '3' : '11'}>
                    <div>
                    <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
  <strong>{domain.domainName}</strong>
</p>
                      <p><strong>Phone:</strong> {domain.phone}</p>
                      <p><strong>Requirement:</strong> {domain.requirement}</p>
                      <p><strong>Description:</strong> {domain.description ? domain.description : 'No description is provided'}</p>
                      <p><strong>Value:</strong> {domain.orderValue}</p>
                      <p><strong>Payment:</strong> {domain.orderPayment}</p>
                      <p><strong>Capture Method:</strong> {domain.capture}</p>
                      <p><strong>Date Captured:</strong> {domain.orderDate}</p>
                      <p><strong>Tracking ID:</strong> {domain.id}</p>
              
                    
                    </div>
                  </td>
                </tr>
              );
            }
  
            return rows;
          })}
        </tbody>
      </table>
 
  
  
      );
    };

    
    
      const NxtNavbuttonStyle = {
    
        position: 'absolute',
      
        right: '10px',
       
        
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'right', 
       
          margin: '0 5px', // Margin between buttons
          padding: '8px', // Padding inside buttons
          cursor: 'pointer', // Cursor style
          backgroundColor: 'transparent',
        };
      
        const PreNavbuttonStyle = {
      
          position: 'absolute',
      
        right: '40px',
       
        
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'right', 
       
          margin: '0 5px', // Margin between buttons
          padding: '8px', // Padding inside buttons
          cursor: 'pointer', // Cursor style
          backgroundColor: 'transparent',
        };
        
        const disabledNxtNavButtonStyle = {
          ...NxtNavbuttonStyle,
      
          cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
          color: 'grey', // Set text color to grey for disabled buttons
      
        alignItems: 'center',
        justifyContent: 'center',
        };
      
        const disabledPreNavButtonStyle = {
          ...PreNavbuttonStyle,
      
          cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
          color: 'grey', // Set text color to grey for disabled buttons
      
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'transparent',
        };
        
        
        const NaviconStyle = {
          marginRight: '5px', // Margin between icon and text
        };
      
        
        const handlePreviousPage = () => {
          const newStartIndex = Math.max(startIndex - 3, 0);
          setStartIndex(newStartIndex);
          setEndIndex(newStartIndex + 3);
        };
    
        const handleNextPage = () => {
          const newStartIndex = endIndex;
          const newEndIndex = Math.min(endIndex + 3, allDomains.length);
          setStartIndex(newStartIndex);
          setEndIndex(newEndIndex);
        };    

return (

    <div>
        <h2>All Affiliate Orders

        <button
style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
onClick={handlePreviousPage}
disabled={startIndex <= 0}
>
<Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
style={endIndex >= allDomains.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
onClick={handleNextPage}
disabled={endIndex >= allDomains.length}
>
<Icon icon="carbon:next-filled" color={endIndex >= allDomains.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>
        </h2>
        {renderDomainList()}
    </div>
);
}

export default AdminAll;

