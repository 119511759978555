// src/components/authentication/ResetPassword.js

import React, { useState } from "react";
import { Link } from "react-router-dom";
import { auth } from "../../firebase";
import { sendPasswordResetEmail } from "firebase/auth";
import "./ResetPassword.css";
import Notification from '../../Notification';
import { Icon } from '@iconify/react';

function ResetPassword() {
  const [email, setEmail] = useState("");
  const [resetSent, setResetSent] = useState(false);
  const [notification, setNotification] = useState(null);

  const resetPassword = async (email) => {
    try {
      // Call sendPasswordResetEmail with the provided email
      await sendPasswordResetEmail(auth, email);
      // Log success message
      console.log("Password reset email sent");
      return true; // Indicate success
    } catch (error) {
      // Log and handle errors
      console.error("Error sending reset email:", error);
      throw error; // Re-throw the error for handling in the component
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Call the resetPassword function with the provided email
      await resetPassword(email);
      // Set a flag to indicate that the reset email has been sent
      setResetSent(true);
      setNotification('Please check your email inbox to reset password.');
    } catch (error) {
      setNotification('Error sending email to reset password.');
      console.error("Error sending reset email:", error);
    }
  };

  const resetpasswordbtnStyle = {
    margin: '5px',
    padding: '6px 12px',
    fontSize: '12px',
    border: 'none',
    borderRadius: '5px',
    backgroundColor: 'blue',
    color: 'white',
    cursor: 'pointer'
  };

  const homeButtonStyle = {

    position: 'fixed',
  
    left: '10px',
    top: '20px',
    
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'right', 
   
      margin: '0 5px', // Margin between buttons
      padding: '8px', // Padding inside buttons
      cursor: 'pointer', // Cursor style
    };

  return (
    <div className="top-class">

    
    <main className="main">

<div>


</div>
<div className="reset-password-parent-container">
<div className="header-account-settings">

      {notification && (
        <Notification message={notification} duration={2000} onClose={() => setNotification(null)} />
      )}
        <section className="wrapper">
          <div className="heading">
          
            <h1 className="text text-medium">Reset your password for Affiliate Program</h1>
            <p className="text text-small">After "Send Email To Reset Password," look for a new password that is sent to your email.</p>
          </div>
          <form name="resetpassword" className="form" onSubmit={handleSubmit}>
            <div className="input-control">
              <label htmlFor="email" className="input-label" hidden>
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="input-field"
                placeholder="Email Address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="input-control">
              <input
                type="submit"
                name="submit"
                className="input-submit"
                value="Send email to reset password"
                // Enable the submit button only when email is filled
                disabled={!email}
              />
            </div>
          </form>
          {resetSent && (
            <div className="message">
              <p className="text text-normal">
                A password reset email has been sent to your inbox.
              </p>
            </div>
          )}
          <div className="method-control">
            <p className="text text-normal">
              Are you done?{" "}
              <span>
                <Link to="/sign-in" className="text text-links">
                  Return to Sign In
                </Link>
              </span>
            </p>
          </div>
        </section>
        </div>
      </div>
    </main>
    </div>
  );
}

export default ResetPassword;
