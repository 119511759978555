import React, { useState, useEffect } from 'react';
import './Tooltip.css'; // Import your CSS file for styling

const Tooltip = ({ text, children }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [animationDuration, setAnimationDuration] = useState(0); // Initial duration in seconds

  const toggleTooltip = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    // Calculate animation duration based on the number of characters in the text
    const numberOfCharacters = text.length;
    const singleCharacterDuration = 0.015; // Duration for each character in seconds
    const calculatedDuration = numberOfCharacters * singleCharacterDuration;

    // Set the animation duration dynamically
    setAnimationDuration(calculatedDuration);
  }, [text]);

  return (
    <div className="tooltip-container" onClick={toggleTooltip}>
      {children}
      {isVisible && (
        <div className="tooltip typing-animation" style={{ animation: `typing ${animationDuration}s steps(${text.length}, end)` }}>
          {text}
        </div>
      )}
    </div>
  );
};

export default Tooltip;
