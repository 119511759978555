import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Profile from './Profile';
import './UserProfilePopup.css';

function UserProfilePopup() {
  const { userId } = useParams();
  const [isPopupVisible, setPopupVisible] = useState(true);
  const [selectedUserId, setSelectedUserId] = useState(null); // State to manage selected user ID for the popup


  const showProfilePopup = (userId) => {
    setSelectedUserId(userId); // Set the selected userId to display in the profile popup
    setPopupVisible(true); // Show the popup
  };



  return (
    <div className={`user-profile-popup ${isPopupVisible ? 'visible' : 'hidden'}`}>
      {/* Conditional rendering of Profile component */}
      {isPopupVisible && userId && <Profile userId={userId} />}
     
    </div>
  );
}

export default UserProfilePopup;
