import React, { useState, useEffect } from 'react';
import { collection, getDocs, doc, updateDoc, onSnapshot, query, where } from 'firebase/firestore';
import { dbFirestore, auth, signOut } from '../../firebase';
import { Link } from "react-router-dom";
import { Icon } from '@iconify/react';
import { useNavigate } from "react-router-dom";
import { useParams } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from "firebase/auth";


function MonthlyPay({ isEmailVerifiedState }) {
    const [users, setUsers] = useState([]);
    const [expandedRows, setExpandedRows] = useState({});

    const [startIndex, setStartIndex] = useState(0);
    const [endIndex, setEndIndex] = useState(3);

    const { userId } = useParams();

    const [user, setUser] = useState(null);
  
    const [currentMonthYear, setCurrentMonthYear] = useState(
      new Date().toLocaleDateString('default', { month: 'long', year: 'numeric' })
  );

   const [timeUntilReset, setTimeUntilReset] = useState('');


    const navigate = useNavigate();
  
    useEffect(() => {
      // Listen for authentication state changes
      const unsubscribe = auth.onAuthStateChanged((user) => {
        if (user) {
          // User is signed in, update the user state
          setUser(user);
        } else {
          // User is signed out, redirect to sign-in page
          navigate('/sign-in'); // Replace '/signin' with your actual sign-in route
        }
      });
  
      // Clean up the subscription to avoid memory leaks
      return () => unsubscribe();
    }, [navigate]);
    
       // Function to handle user logout
       const handleLogout = async () => {
        try {
          await signOut(auth);
          navigate('/sign-in'); // Redirect to the login page after logout
        } catch (error) {
          console.error('Error logging out:', error);
        }
      };


    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const usersRef = collection(dbFirestore, 'account');
                const q = query(usersRef, where("payCycle", "==", "Monthly"));
                const usersSnapshot = await getDocs(q);
                const usersData = usersSnapshot.docs.map(doc => {
                    const userData = doc.data();
                    return {
                        id: doc.id,
                        ...userData,
                       
                        previousMonthEarnings: userData.Earnings ? userData.Earnings.previousMonthEarnings : 0, // Set to 0 if Earnings or currentMonthEarnings is not available
                    };
                });
                setUsers(usersData);
            } catch (error) {
                console.error('Error fetching users:', error);
            }
        };
    
        fetchUsers();
    
        // Subscribe to real-time updates
        const unsubscribe = onSnapshot(collection(dbFirestore, 'account'), (snapshot) => {
            const updatedUsers = snapshot.docs.map(doc => {
                const userData = doc.data();
                return {
                    id: doc.id,
                    ...userData,
                   
                    previousMonthEarnings: userData.Earnings ? userData.Earnings.previousMonthEarnings : 0, // Set to 0 if Earnings or currentMonthEarnings is not available
                };
            }).filter(user => user.payCycle === "Monthly"); // Additional filter here
            setUsers(updatedUsers);
        });
    
        // Clean up the listener on unmount
        return () => unsubscribe();
    }, []);
    

   

  const handleActionCheckboxChange = async (userId, checked) => {
      try {
          const userDocRef = doc(dbFirestore, 'account', userId);
          await updateDoc(userDocRef, {
              isChecked: checked,
              paymentStatus: checked ? users.find(user => user.id === userId).previousMonthEarnings : 0
          });
      } catch (error) {
          console.error('Error updating action checkbox:', error);
      }
  };

  // Function to handle reset button click
  const handleResetButtonClick = async () => {
    try {
        const resetUsers = users.map(user => ({
            ...user,
            isChecked: false,
            paymentStatus: 0
        }));

        setUsers(resetUsers); // Update local state

        // Update Firestore for each user
        resetUsers.forEach(async user => {
            const userDocRef = doc(dbFirestore, 'account', user.id);
            await updateDoc(userDocRef, {
                isChecked: false,
                paymentStatus: 0
            });
        });
    } catch (error) {
        console.error('Error resetting checkboxes:', error);
    }
};

// Calculate time until next month
useEffect(() => {
    const calculateTimeUntilNextMonth = () => {
        const now = new Date();
        const nextMonth = new Date(now.getFullYear(), now.getMonth() + 1, 1);
        nextMonth.setHours(0, 0, 0, 0);

        const timeDifference = nextMonth.getTime() - now.getTime();
        const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

        setTimeUntilReset(`${days}d ${hours}h ${minutes}m ${seconds}s`);
    };

    calculateTimeUntilNextMonth();
    const interval = setInterval(calculateTimeUntilNextMonth, 1000);

    return () => clearInterval(interval);
}, []);





    

    const profileStyle = {
        margin: '5px',
        padding: '6px 12px',
        fontSize: '15px',
        border: 'none',
        borderRadius: '5px',
        backgroundColor: 'blue',
        color: 'white',
        cursor: 'pointer'
      };

      const renderUsersList = () => {
  
        // Sort the domains by timestamp in descending order
      const sortedDomains = users.slice().sort((a, b) => {
        // Assuming each domain entry has a timestamp field called 'timestamp'
        return new Date(b.timestamp) - new Date(a.timestamp);
      });
        const isSmallScreen = window.innerWidth <= 768;
    
        const usersToDisplay = sortedDomains.slice(startIndex, endIndex);
    
        
        const thStyle = {
          backgroundColor: '#f0f0f0',
          padding: '8px',
          textAlign: 'left',
          borderBottom: '1px solid #ddd',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: isSmallScreen ? '150px' : '200px',
        };
    
        const tdStyle = {
          padding: '8px',
          borderBottom: '1px solid #ddd',
          backgroundColor: '#ffffff',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          maxWidth: isSmallScreen ? '100px' : '200px',
        };
    
        const TooltipbtnStyle = {
          backgroundColor: '#eeeeee',
          color: '#000000',
          padding: '2px 4px', // Adjust padding as needed for your content
          border: 'none',
          borderRadius: '5px', // Border radius for rounded corners
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center', // Center content vertically
          justifyContent: 'center', // Center content horizontally
         
          boxShadow: '0 2px 4px rgba(0, 0, 0, 0.2)', // Optional box shadow for a raised effect
        };
    
        const toggleRowExpansion = (domainId) => {
          setExpandedRows((prevExpandedRows) => ({
            ...prevExpandedRows,
            [domainId]: !prevExpandedRows[domainId],
          }));
        };
    
        const tableStyle = {
          borderCollapse: 'collapse',
          width: '100%',
          textAlign: 'left',
      
        };

        const expandbtnStyle = {
            margin: '5px',
            padding: '6px 12px',
            fontSize: '12px',
            border: 'none',
            borderRadius: '5px',
            backgroundColor: 'blue',
            color: 'white',
            cursor: 'pointer'
          };

        return (

      
            <table style={tableStyle}>
             
              <thead>
                <tr>
                  <th style={thStyle}>User No.</th>
                  <th style={thStyle}>Full Name</th>
                  <th style={thStyle}>Bank Account</th>
                  {isSmallScreen && <th style={thStyle}></th>}
                  {!isSmallScreen && (
                    <>
                      
                      <th style={thStyle }>Profile</th>
                      <th style={thStyle}>Previous Month Earnings</th>
                      <th style={thStyle}>Payment Status</th>
                      <th style={thStyle}>Action</th>
                      <th style={thStyle}></th>
                    </>
                  )}
                </tr>
              </thead>
              <tbody>
                {usersToDisplay.map((user, index) => {
                  const rows = [
                    <tr key={user.id}>
                      
                      <td style={tdStyle}>{index + 1}</td>
                      <td style={tdStyle}>{user.fullName}</td>
                      <td style={tdStyle}>{user.accountNumber}</td>
                      {isSmallScreen && (
                        <td style={tdStyle}>
                          <button style={ expandbtnStyle } className="expand-button" onClick={() => toggleRowExpansion(users.id)}>
                            {expandedRows[users.id] ? '-' : '+'}
                          </button>
                        </td>
                      )}
                      {!isSmallScreen && (
                        <>
                           <td style={tdStyle}><Link to={`/profile/${user.id}`}>
    <button style={profileStyle}>Profile</button>
</Link></td> 
                          <td style={tdStyle}>R{user.previousMonthEarnings}</td>
                          
                          <td>R{user.paymentStatus}</td>
                          <td style={tdStyle}><input
                                    type="checkbox"
                                    checked={user.isChecked || false}
                                    onChange={(e) => handleActionCheckboxChange(user.id, e.target.checked)}
                                /></td>
                          <td style={tdStyle}></td>
                        </>
                      )}
                    </tr>
                  ];
        
                  if (isSmallScreen && expandedRows[users.id]) {
                    rows.push(
                      <tr key={`${users.id}-expanded`}>
                        <td colSpan={isSmallScreen ? '3' : '8'}>
                          <div>
                          <p style={{ color: 'grey', textAlign: 'center', lineHeight: '1.5' }}>
        <strong>{user.fullName}</strong>
      </p>

                            <p><strong>Profile:</strong> <Link to={`/profile/${user.id}`}>
    <button style={profileStyle}>Profile</button>
</Link></p>
                            <p><strong>Previous Month Earnings:</strong> R{user.previousMonthEarnings}</p>
                            <p><strong>Payment Status:</strong> R{user.paymentStatus}</p>
                            <p><strong>Action:</strong> <input
                                    type="checkbox"
                                    checked={user.isChecked || false}
                                    onChange={(e) => handleActionCheckboxChange(user.id, e.target.checked)}
                                /></p>
                            <p></p>
                    
                          
                          </div>
                        </td>
                      </tr>
                    );
                  }
        
                  return rows;
                })}
              </tbody>
            </table>
      
      
          );
        };
    
        
        
          const NxtNavbuttonStyle = {
        
            position: 'absolute',
          
            right: '10px',
           
            
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'right', 
           
              margin: '0 5px', // Margin between buttons
              padding: '8px', // Padding inside buttons
              cursor: 'pointer', // Cursor style
              backgroundColor: 'transparent',
            };
          
            const PreNavbuttonStyle = {
          
              position: 'absolute',
          
            right: '40px',
           
            
            alignItems: 'center',
            justifyContent: 'center',
            textAlign: 'right', 
           
              margin: '0 5px', // Margin between buttons
              padding: '8px', // Padding inside buttons
              cursor: 'pointer', // Cursor style
              backgroundColor: 'transparent',
            };
            
            const disabledNxtNavButtonStyle = {
              ...NxtNavbuttonStyle,
          
              cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
              color: 'grey', // Set text color to grey for disabled buttons
          
            alignItems: 'center',
            justifyContent: 'center',
            };
          
            const disabledPreNavButtonStyle = {
              ...PreNavbuttonStyle,
          
              cursor: 'not-allowed', // Change cursor to not-allowed for disabled buttons
              color: 'grey', // Set text color to grey for disabled buttons
          
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            };
            
            
            const NaviconStyle = {
              marginRight: '5px', // Margin between icon and text
            };
          
            
            const handlePreviousPage = () => {
              const newStartIndex = Math.max(startIndex - 3, 0);
              setStartIndex(newStartIndex);
              setEndIndex(newStartIndex + 3);
            };
        
            const handleNextPage = () => {
              const newStartIndex = endIndex;
              const newEndIndex = Math.min(endIndex + 3, users.length);
              setStartIndex(newStartIndex);
              setEndIndex(newEndIndex);
            };    

    return (

        <div>
            <h2>Month Payout {currentMonthYear}
            <button onClick={handleResetButtonClick}>Reset All Checkboxes</button>
            {/* Display countdown for next reset */}
            Time until next reset: {timeUntilReset}
            <button
  style={startIndex <= 0 ? disabledPreNavButtonStyle : PreNavbuttonStyle}
  onClick={handlePreviousPage}
  disabled={startIndex <= 0}
>
  <Icon icon="carbon:previous-filled" color={startIndex <= 0 ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />

</button>

<button
  style={endIndex >= users.length ? disabledNxtNavButtonStyle : NxtNavbuttonStyle}
  onClick={handleNextPage}
  disabled={endIndex >= users.length}
>
  <Icon icon="carbon:next-filled" color={endIndex >= users.length ? 'grey' : 'black'} width="24" height="24" style={NaviconStyle} />
</button>
            </h2>
            {renderUsersList()}
        </div>
    );
}


export default MonthlyPay;